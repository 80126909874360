<style scoped>
.c-text-mist {
  filter: blur(0.25em);
  cursor: pointer;
}
.c-banlist-header {
	cursor: pointer;
	user-select: none;
}
</style>
<script>
import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import TimeAgo from 'vue2-timeago';
import Multiselect from "vue-multiselect";
import {HalfCircleSpinner} from "epic-spinners";
import { VclList } from 'vue-content-loading';

import { BanType } from "@/enums";
import {get_acsrf_token} from "@/methods";
import CopyableInput from "@/components/CopyableInput";


export default {
  props: ['cftools_id'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableInput,
    Multiselect,
    VclList,
    // eslint-disable-next-line vue/no-unused-components
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    TimeAgo,
	  BanListProfile: () => import('@/components/widgets/BanListProfile'),
    BanReason: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanReason'),
    BanDescription: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanDescription'),
    BanExpiration: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanExpiration'),
    BanFileUpload: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanFileUpload'),
    BanLinkUpload: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/BanLinkUpload'),
    FeaturedBanModal: () => import('@/components/lazy/banmanager/view/desktop/bancomponents/FeaturedBan')

  },
  validations: {
    banForm: {
      identifier: {required, custom: true},
      reason: {required, minLength: minLength(3), maxLength: maxLength(40)},
      description: {maxLength: maxLength(400)}
    },
    reportForm: {
      message: {required, minLength: minLength(5), maxLength: maxLength(1280)}
    }
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    fetchData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/bans`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.bans = data.bans;
          this.flags = data.flags;
          this.banlists = data.banlists;
          this.isScoped = data.scoped;
          this.unverifiedBanCount = data.unverified;

          this.availableBanlists = [];
          Object.keys(this.banlists).forEach((banlist_id) => {
            let banlist = this.banlists[banlist_id];
            if(banlist.isBanned || !(banlist.permissions.ban.temporary || banlist.permissions.ban.permanent)) return;
            this.availableBanlists.push(banlist);
          });
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    overridePersona(personaName, avatar) {
      this.$parent.$emit('overridePersona', personaName, avatar);
    },
    currentDateTime(date) {
      if(!date || date === null) date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    async updateBanExpiration() {
      if(!this.banForm.days || this.banForm.days === '') this.banForm.days = 0;
      let days = parseInt(this.banForm.days);
      let date = new Date();
      date.setDate(date.getDate() + days);
      this.banForm.expiration = this.currentDateTime(date);
    },
    async issueBan() {
      this.$v.banForm.$touch();
      if(this.$v.banForm.reason.$error || !this.banForm.type) return;

      this.banInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let files = [];
        this.banForm.files.forEach((file) => {
          files.push([file.resource_id, file.name]);
        });

        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          type: this.banForm.type,
          banlist_id: this.selectedBanlist.id,
          reason: this.banForm.reason,
          identifier: this.banForm.identifier,
          description: this.banForm.description,
          links: this.banForm.links,
          files: files,
          permanent: this.banForm.permanent,
          expiration: this.banForm.expiration,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.selectedBanlist.id}/ban`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.banForm = {
            type: 'cftools_id',
            identifier: this.cftools_id,
            reason: null,
            description: null,
            permanent: this.permanentAvailable ? true : false,
            expiration: this.banForm.expiration,
            links: [],
            files: []
          };
          this.selectedBanlist = null;

          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.add.success')
          });
          this.fetchData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.banInProgress = false;
    },
    async revokeBan(banId, banlistId, force_delete) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          ban_id: banId,
          force_delete: force_delete
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${banlistId}/ban-revoke`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          let data = await response.json();
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.remove.success')
          });
          if(this.$refs.featuredBan.$data.isShow) {
            if(data.deleted) {
              this.$refs.featuredBan.hide();
            } else await this.detailedBanView(banId, banlistId);
          }
          this.fetchData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
    async detailedBanView(banId, banlistId) {
      this.loadingBanDetails = true;
      this.loadingBanDetailsError = false;
      this.featuredBanId = banId;
      this.currentBanListId = banlistId;
      this.currentBanList = this.banlists[banlistId];
      this.$refs.featuredBan.show();

      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/banmanager/${banlistId}/details`);
        url.searchParams.append('ban_id', this.featuredBanId);

        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });

        if(response.ok) {
          let data = await response.json();
          this.featuredBan = data.ban;
          // Format array for future use.
          let newFiles = [];
          for (let file of this.featuredBan.evidence.files) {
            newFiles.push({'resource_id': file[0], 'name': file[1]})
          }
          this.featuredBan.evidence.files = newFiles;
          //
        } else {
          if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        this.loadingBanDetailsError = true;
        console.log(`[ERROR] ${error}`);
      }
      this.loadingBanDetails = false;
    },
    async reportOpenModal(banId, banlistId) {
      this.reportForm.ban_id = banId;
      this.reportForm.banlist_id = banlistId;

      this.$refs.reportBan.show();
    },
    async reportBan() {
      this.$v.reportForm.$touch();
      if(this.$v.reportForm.$error || !this.reportForm.ban_id || !this.reportForm.banlist_id || !this.reportForm.checked) return;

      this.banInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          ban_id: this.reportForm.ban_id,
          message: this.reportForm.message
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.reportForm.banlist_id}/ban-report`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          let data = await response.json();
          this.$swal({
            icon: 'success',
            text: this.$t('profile.bans.list.report.form.success')
          });
          this.reportForm.message = "";
          this.reportForm.checked = false;
          this.fetchData();
          this.$refs.reportBan.hide();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.banInProgress = false;
    },
    async onSelectedBanlistChange() {
      if (this.selectedBanlist === null) return;
      this.banForm.permanent = this.selectedBanlist.permissions.ban.permanent;
    },
	  async showBanListProfile(banId) {
			// alert('Got it.');
		  const infoProfile = this.$refs[`banListProfile-${banId}`];
			infoProfile.show();
	  },
    async banRenewed(){
      if(this.$refs.featuredBan.$data.isShow) {
        await this.detailedBanView(this.featuredBanId, this.currentBanListId);
      }
      await this.fetchData();
    },
    async banDeleted(){
      if(this.$refs.featuredBan.$data.isShow) {
        this.$refs.featuredBan.hide();
      }
      await this.fetchData();
    },
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
    temporaryAvailable() {
      let temporary = true;
      this.availableBanlists.forEach((banlist) => {
        if(!banlist.permissions.ban.temporary) temporary = false;
      });
      return temporary;
    },
    permanentAvailable() {
      let permanent = true;
      this.availableBanlists.forEach((banlist) => {
        if(!banlist.permissions.ban.permanent) permanent = false;
      });
      return permanent;
    }
  },
  mounted() {
    this.fetchData();
    this.banForm.identifier = this.cftools_id;
    if(!this.permanentAvailable) {
      this.banForm.permanent = false;
    }
    this.$nextTick(() => {
      this.updateBanExpiration();
    });
  },
  data() {
    return {
      // Enums
      BanType:BanType,
      // Enums end

      ready: false,
      error: false,
      bans: null,
      banlists: {},
      flags: null,
      isScoped: false,
      unverifiedBanCount: 0,

      // Add ban
      staticTrue: 1,
      banInProgress: false,
      identifierValid: true,
      selectedBanlist: null,
      selectedBanlists: [],
      availableBanlists: [],
      banForm: {
        type: 'cftools_id',
        identifier: null,
        reason: null,
        description: null,
        permanent: true,
        expiration: this.currentDateTime(),
        days: 0,
        links: [],
        files: []
      },
      reportForm: {
        ban_id: null,
        banlist_id: null,
        message: null,
        checked: false
      },

      // Ban details
      loadingBanDetails: true,
      loadingBanDetailsError: false,
      featuredBanId: null,
      featuredBan: null,

      // Ban table
      fields: [
        {
          key: 'banlist',
          label: this.$t('profile.bans.list.table.headers.banlist'),
          sortable: false
        },
        {
          key: 'date',
          label: this.$t('profile.bans.list.table.headers.date'),
          sortable: false
        },
        {
          key: 'expiration',
          label: this.$t('profile.bans.list.table.headers.expiration'),
          sortable: false
        },
        {
          key: 'reason',
          label: this.$t('profile.bans.list.table.headers.reason'),
          sortable: false
        },
        {
          key: 'evidence',
          label: this.$t('profile.bans.list.table.headers.evidence'),
          sortable: false
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        }
      ],
      currentBanListId: null,
      currentBanList: null,
    }
  },
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-11">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
	                <div class="row" v-if="flags.controversy">
		                <div class="col">
											<div class="card card-body border border-info">
												<h4 class="text-info p-0">
													{{ $t('profile.bans.controversy.title') }}
												</h4>
												<hr/>
												<div class="text-info h6 p-0">
													{{ $t('profile.bans.controversy.description') }}
												</div>
											</div>
		                </div>
	                </div>
                  <!-- Ban entries -->
                  <div class="row">
                    <div class="col">
                      <div role="tablist">
                        <b-card no-body class="shadow-none settings-collapse">
                          <a v-b-toggle.banEntries class="text-dark" href="javascript: void(0);">
                            <b-card-header header-tag="header" role="tab">
                              <h3 class="m-0 d-inline-flex">
                                {{ $t('profile.bans.list.title') }}

                              </h3>
                              <div class="d-inline-flex ml-1 text-muted">
                                {{ $t('profile.bans.list.description') }}
                              </div>
                              <div class="d-inline-flex float-right">
                                <div class="btn btn-sm btn-dark">
                                  {{ $t('profile.bans.list.expand') }}
                                </div>
                              </div>
                            </b-card-header>
                          </a>
                          <b-collapse id="banEntries" :visible="!flags.controversy" accordion="bans" role="tabpanel">
                            <b-card-body>
                              <b-card-text>
                                <template v-if="bans.length">
                                  <b-table class="table-responsive-sm" :items="bans" :fields="fields" :per-page="0">
                                    <template #cell(banlist)="data">
                                      <div class="d-inline mr-2">
                                        <template v-if="data.item.flags.verified">
                                          <i class="fas fa-badge-check text-white align-middle align-self-center font-size-18" v-b-tooltip.hover :title="$t('profile.bans.list.public')" />
                                        </template>
                                        <template v-else-if="!data.item.flags.verified && !data.item.flags.listed && data.item.flags.self">
                                          <i class="fas fa-eye-slash text-warning align-middle align-self-center font-size-18" v-b-tooltip.hover :title="$t('profile.bans.list.scoped')" />
                                        </template>
                                      </div>
                                      <div class="d-inline-block">
                                        <h5 class="mb-0 align-middle d-inline-block">
                                          <div class="c-banlist-header" v-on:click="banlists[data.item.banlist_id].profile ? showBanListProfile(data.item.id) : null">
	                                          <template v-if="banlists[data.item.banlist_id]">
		                                          {{ banlists[data.item.banlist_id].identifier }}
	                                          </template>
	                                          <template v-else>
		                                          {{ data.item.banlist_id }}
	                                          </template>
	                                          <template v-if="banlists[data.item.banlist_id].category">
		                                          <span class="badge badge-secondary text-black">
		                                            {{ $t(`banmanager.view.profile.category.options.${banlists[data.item.banlist_id].category}`) }}

			                                          <i class="fad fa-info-circle text-default"/>
		                                          </span>
	                                          </template>
	                                          <small class="ml-1" v-else-if="banlists[data.item.banlist_id].profile">
		                                          <i class="fad fa-info-circle"/>
	                                          </small>
                                          </div>
                                          <template v-if="data.item.sharing.shared">
                                            <template v-if="data.item.sharing.linked_ban.exists">
                                              <template v-if="data.item.sharing.linked_ban.revoked">
                                                <template v-if="data.item.sharing.linked_ban.banlist_id">
                                                  <small>
                                                    <template v-if="banlists[data.item.sharing.linked_ban.banlist_id]">
                                                      <i class="fad fa-share-all align-middle align-self-center text-warning" v-b-tooltip.hover :title="`Shared ban (Original Revoked) - ${banlists[data.item.sharing.linked_ban.banlist_id].identifier}`" />
                                                    </template>
                                                    <template v-else>
                                                      <i class="fad fa-share-all align-middle align-self-center text-primary" v-b-tooltip.hover :title="`Shared ban (Original Revoked) - ${banlists[data.item.sharing.linked_ban.banlist_id]} (Data partially unavailable)`" />
                                                    </template>
                                                  </small>
                                                </template>
                                                <template v-else>
                                                  <small>
                                                    <i class="fad fa-share-all align-middle align-self-center text-warning" v-b-tooltip.hover :title="`Shared ban (Original Deleted)`" />
                                                  </small>
                                                </template>
                                              </template>
                                              <template v-else>
                                                <small>
                                                  <template v-if="banlists[data.item.sharing.linked_ban.banlist_id]">
                                                    <i class="fad fa-share-all align-middle align-self-center text-primary" v-b-tooltip.hover :title="`Shared ban - ${banlists[data.item.sharing.linked_ban.banlist_id].identifier}`" />
                                                  </template>
                                                  <template v-else>
                                                    <i class="fad fa-share-all align-middle align-self-center text-primary" v-b-tooltip.hover :title="`Shared ban - ${banlists[data.item.sharing.linked_ban.banlist_id]} (Data partially unavailable)`" />
                                                  </template>
                                                 </small>
                                              </template>
                                            </template>
                                            <template v-else>
                                              <small>
                                                <i class="fad fa-share-all align-middle align-self-center" v-b-tooltip.hover title="Shared ban (Legacy)" />
                                              </small>
                                            </template>
                                          </template>
                                        </h5>

                                        <!-- Ban list Info popup -->
	                                      <b-dropdown toggle-class="d-none" class="ml-2 d-inline-block" menu-class="dropdown-menu-lg p-0 rounded" :ref="'banListProfile-'+data.item.id">
                                          <template v-slot:button-content></template>
                                          <BanListProfile :banlist_id="data.item.banlist_id" v-if="banlists[data.item.banlist_id].profile"/>
                                        </b-dropdown>
                                      </div>
                                    </template>
                                    <template #cell(reason)="data">
                                      <template v-if="data.item.flags.profanity">
                                        <span class="c-text-mist" @click="e => e.target.classList.toggle('c-text-mist')"  v-b-tooltip.hover title="This ban reason contains profanity and has been hidden. Click to reveal.">
                                          {{ data.item.reason }}
                                        </span>
                                      </template>
                                      <template v-else>
                                        {{ data.item.reason }}
                                      </template>
                                    </template>
                                    <template #cell(date)="data">
                                      {{ $d(parseDate(data.item.created_at), 'datetime', getDTLocale()) }}
                                    </template>
                                    <template #cell(expiration)="data">
                                      <template v-if="data.item.expires_at !== false">
                                        {{ $d(parseDate(data.item.expires_at), 'datetime', getDTLocale()) }}
                                      </template>
                                      <template v-else>
                                        <b>
                                          {{ $t('profile.bans.list.never') }}
                                        </b>
                                      </template>
                                    </template>
                                    <template #cell(evidence)="data">
                                      <template v-if="data.item.files || data.item.links">
                                        <span class="ml-1 mr-1" v-for="file in data.item.files" :key="file[0]+Math.random()">
                                          <i class="fad fa-file-alt align-middle"></i>
                                          <a :href="'https://usrcdn.cftools.cloud/s1/'+file[0]+'/'+file[1]" target="_blank">
                                            {{file[1]}}
                                          </a>
                                        </span>
                                        <span class="ml-1 mr-1" v-for="link in data.item.links" :key="link+Math.random()">
                                          <i class="fad fa-external-link align-middle"></i>
                                          <a :href="link" target="_blank">
                                            {{link}}
                                          </a>
                                        </span>
                                      </template>
                                      <template v-else>
                                        <!-- {{ $t('profile.bans.list.noevidence') }} -->
                                        -
                                      </template>
                                    </template>
                                    <template #cell(actions)="data">
                                      <button class="btn ml-1 mr-1 btn-sm btn-rounded btn-primary" v-on:click="detailedBanView(data.item.id, data.item.banlist_id)" v-if="!data.item.public">
                                        <i class="fad fa-info" />
                                        {{ $t('banmanager.view.database.details') }}
                                      </button>

                                      <template v-if="data.item.reportable && flags.canReport">
                                        <button class="btn btn-sm btn-danger" v-if="data.item.flags.verified || data.item.flags.listed" v-b-tooltip.hover :title="$t('profile.bans.list.report.prompt')" v-on:click="reportOpenModal(data.item.id, data.item.banlist_id)">
                                          <i class="far fa-flag" />
                                        </button>
                                      </template>
                                    </template>
                                  </b-table>
                                </template>
                                <template v-else>
                                  <div class="row">
                                    <div class="col">
                                      <h5 class="text-muted text-uppercase">{{ $t('profile.bans.list.none') }}</h5>
                                    </div>
                                  </div>
                                </template>
                                <div class="row" v-if="unverifiedBanCount">
                                  <div class="col">
                                    <span class="small">
                                      + <b>{{ unverifiedBanCount }}</b> {{ $t('profile.bans.list.unlisted') }}
                                    </span>
                                  </div>
                                </div>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                    </div>
                  </div>

                  <!-- Issue ban -->
                  <div class="row" v-if="flags.canBan">
                    <div class="col">
                      <div role="tablist">
                        <b-card no-body class="shadow-none settings-collapse">
                          <a v-b-toggle.issueBan class="text-dark" href="javascript: void(0);">
                            <b-card-header header-tag="header" role="tab">
                              <h3 class="m-0 d-inline-flex">
                                {{ $t('profile.bans.issue.title') }}
                              </h3>
                              <div class="d-inline-flex float-right">
                                <div class="btn btn-sm btn-dark">
                                  {{ $t('profile.bans.issue.expand') }}
                                </div>
                              </div>
                            </b-card-header>
                          </a>
                          <b-collapse id="issueBan" accordion="bans" role="tabpanel">
                            <b-card-body>
                              <b-card-text>
                                <div class="row">
                                  <div class="col-lg-12 co-sm-12">
                                    <h5>{{ $t('profile.bans.issue.banlistselect') }}</h5>
                                    <hr>
                                    <div class="row">
                                      <div class="col-lg-6 col-sm-12">
                                        <multiselect
                                            v-model="selectedBanlist"
                                            label="identifier"
                                            track-by="identifier"
                                            :options="availableBanlists"
                                            :show-labels="false"
                                            :allow-empty="true"
                                            :searchable="true"
                                            @input="onSelectedBanlistChange()"
                                        >
                                          <template slot="singleLabel" slot-scope="props">
                                            <div>
                                              <span class="option__title align-middle h4">
                                                {{ props.option.identifier }}
                                              </span>
                                              <div class="option__desc d-inline align-middle">
                                                <span>
                                                  {{ $t('profile.bans.temporary') }}
                                                  <i class="fas fa-check text-success align-middle align-self-center font-size-14" v-if="props.option.permissions.ban.temporary" />
                                                  <i class="fas fa-times text-danger align-middle align-self-center font-size-14" v-else/>
                                                </span>
                                                <span class="ml-1">
                                                  {{ $t('profile.bans.permanent') }}
                                                  <i class="fas fa-check text-success align-middle align-self-center font-size-14" v-if="props.option.permissions.ban.permanent" />
                                                  <i class="fas fa-times text-danger align-middle align-self-center font-size-14" v-else/>
                                                </span>
                                              </div>
                                            </div>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div>
                                              <div class="option__desc d-inline ml-2">
                                                <span class="option__title align-middle h4">
                                                  {{ props.option.identifier }}
                                                </span>
                                                <span class="option__small align-middle ml-2 h6">
                                                  <span>
                                                  {{ $t('profile.bans.temporary') }}
                                                  <i class="fas fa-check text-success align-middle align-self-center font-size-14" v-if="props.option.permissions.ban.temporary" />
                                                  <i class="fas fa-times text-danger align-middle align-self-center font-size-14" v-else/>
                                                </span>
                                                <span class="ml-1">
                                                  {{ $t('profile.bans.permanent') }}
                                                  <i class="fas fa-check text-success align-middle align-self-center font-size-14" v-if="props.option.permissions.ban.permanent" />
                                                  <i class="fas fa-times text-danger align-middle align-self-center font-size-14" v-else/>
                                                </span>
                                                </span>
                                              </div>
                                            </div>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row mt-3" v-if="selectedBanlist">
                                  <div class="col-lg-8 co-sm-12">
                                    <h5>{{ $t('banmanager.add.details.title') }}</h5>
                                    <hr>
                                    <div class="row">
                                      <div class="col">
                                        <b-form-radio-group
                                            v-model="banForm.type"
                                            :options="[{text: this.$t('banmanager.add.details.cftools_id.radio'), value: 'cftools_id'},{text: this.$t('banmanager.add.details.multi.radio'), value: 'multi'}]"
                                        ></b-form-radio-group>
                                      </div>
                                    </div>
                                    <div class="row mt-2">
                                      <div class="col-lg-6 col-sm-12">
                                        <!-- Identifier -->
                                        <div class="form-group">
                                          <label for="banFormIdentifier">
                                            <template v-if="banForm.type === 'multi'">
                                              {{ $t('banmanager.add.details.multi.label') }}
                                            </template>
                                            <template v-else-if="banForm.type === 'cftools_id'">
                                              {{ $t('banmanager.add.details.cftools_id.label') }}
                                            </template>
                                            <template v-else>
                                              <b class="text-danger">{{ $t('banmanager.add.details.type') }}</b>
                                            </template>
                                          </label>
                                          <input
                                              id="banFormIdentifier"
                                              v-model="banForm.identifier"
                                              type="text"
                                              class="form-control"
                                              :class="{ 'is-invalid': $v.banForm.identifier.$error || !identifierValid}"
                                              disabled
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                                        <!--               Set Reason.-->
                                        <BanReason :banForm="banForm" :error="$v.banForm.reason.$error"></BanReason>
                                      </div>

                                    </div>
                                    <!--             Set Description.-->
                                    <BanDescription :banForm="banForm"></BanDescription>
                                    <div>
                                      <BanExpiration
                                          :options="selectedBanlist"
                                          :banForm="banForm">
                                      </BanExpiration>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-sm-12 c-sm-spacer">
                                  <!--            Set evidence files.-->
                                      <BanFileUpload
                                          :ban-form="banForm.files"
                                          :banlist_id="selectedBanlist.id"
                                          :createMode="true"
                                          create_ban="true">
                                      </BanFileUpload>
                                    <!--            Set evidence links.-->
                                    <BanLinkUpload
                                        :banLinks="banForm.links"
                                        :createMode="true">
                                    </BanLinkUpload>
                                  </div>
                                  <div class="row-cols-1" style="width: 100%">
                                    <div class="col">
                                      <button class="btn btn-warning btn-block mt-3 text-black" v-on:click="issueBan()" :class="{'disabled': banInProgress}">
                                        <half-circle-spinner
                                            v-if="banInProgress"
                                            :animation-duration="1200"
                                            :size="16"
                                            class="align-middle d-inline-block"
                                        />
                                        {{ $t('profile.bans.issue.button') }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        ref="reportBan"
        size="md"
        :title="$t('profile.bans.list.report.form.title')"
        title-class="font-18"
        hide-footer
      >
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div for="banFormDescription">
                {{ $t('profile.bans.list.report.form.message') }}
              </div>
              <b class="text-small font-size-12" >
                {{ $t('profile.bans.list.report.form.info') }}
              </b>
              <textarea
                  v-model="reportForm.message"
                  type="text"
                  class="form-control mt-2"
                  :placeholder="$t('profile.bans.list.report.form.placeholder')"
                  :class="{ 'is-invalid': $v.reportForm.message.$error }"
                  rows="6"
              />
              <div v-if="$v.reportForm.message.$error" class="invalid-feedback">
                {{ $t('profile.bans.list.report.form.errors.length') }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">

            <div role="alert" class="alert alert-warning">
              <h4 class="alert-heading">
                {{ $t('profile.bans.list.report.disclaimer.title') }}
              </h4>
              {{ $t('profile.bans.list.report.disclaimer.message') }}
              <b-checkbox v-model="reportForm.checked" class="mt-2 text-center text-black">
                {{$t('profile.bans.list.report.disclaimer.consent')}}
              </b-checkbox>
            </div>

            <button class="btn btn-warning text-black btn-block" v-on:click="reportBan()" :class="{'disabled': banInProgress || !reportForm.message}" :disabled="banInProgress || !reportForm.message">
              <half-circle-spinner
                  v-if="banInProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              {{ $t('profile.bans.list.report.form.send') }}
            </button>
          </div>
        </div>
      </b-modal>
      <b-modal
          ref="featuredBan"
          size="lg"
          :title="'Ban #'+featuredBanId"
          title-class="font-18"
          hide-footer
      >
        <FeaturedBanModal
            :featuredBan="featuredBan"
            :options="currentBanList"
            :loadingBanDetails="loadingBanDetails"
            :loadingBanDetailsError="loadingBanDetailsError"
            :banlist_id="currentBanListId"
            :selectedBanList="selectedBanlist"
            @edited="banRenewed()"
            @deleted="banDeleted()"
            @updateRequired="banRenewed()">
        </FeaturedBanModal>
      </b-modal>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="card">
                <div class="card-body text-center">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" width="400px"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
